<template>
	<div class="subjective_view">
		<div class="interMant_top">
			<div class="interTop_left">
				<el-avatar :size="50">
					<img :src="infoData.head_img ? 'http://' + infoData.head_img : circleUrl" />
				</el-avatar>
				<div class="otim">
					姓名:
					<span>{{ infoData.name }}</span>
					性别:
					<span>{{ infoData.sex == 1 ? '男' : infoData.sex == 2 ? '女' : '' }}</span>
					身高:
					<span>{{ infoData.height }}cm</span>
					体重:
					<span>{{ infoData.weight }}kg</span>
					项目:
					<span>{{ infoData.sport_name }}</span>
					运动队:
					<span>{{ infoData.department_name }}</span>
				</div>
			</div>
			<div class="interTop_right">
				<el-date-picker
					v-model="value1"
					@change="dateChange"
					:clearable="false"
					value-format="yyyy-MM-dd"
					format="yyyy-MM-dd"
					type="date"
					placeholder="选择日期"
					:picker-options="customPickerOptions"
					:unlink-panels="true"
				>
				</el-date-picker>
				<span @click="reBack">
					<i class="iconfont iconfanhui"></i>
					返回
				</span>
			</div>
		</div>

		<div class="subjective_content">
			<div class="sub_content_top">
				<span class="more" v-if="moreType" @click="moreClick(false)">更多 <i class="el-icon-caret-bottom iconBottomMove"></i></span>
				<span class="pack" v-else @click="moreClick(true)">收起 <i class="el-icon-caret-top iconTopMove"></i></span>
				<!-- :style="{
                  transform: moreType
                    ? 'rotate(0deg)'
                    : 'rotate(-180deg)',
                    transition: '1s'       
                }" -->
				<!-- <span class="more" @click="moreClick()">{{moreType ? '更多' : '展开'}} <i class="el-icon-caret-bottom iconBottomMove"></i></span> -->
				<div class="top_con">
					<span>体重（kg）</span>
					<el-input @input="valueInput('weight')" @blur="valueChange()" v-model="form.weight" :disabled="edit"></el-input>
				</div>
				<div class="top_con">
					<span>体脂率 (%)</span>
					<el-input @input="valueInput('PhFatRate')" @blur="valueChange()" v-model="form.PhFatRate" :disabled="edit"></el-input>
				</div>
				<div class="top_con">
					<span>瘦体重 (kg)</span>
					<el-input @input="valueInput('excp_fat_qua')" @blur="valueChange()" v-model="form.excp_fat_qua" :disabled="edit"></el-input>
				</div>
				<div class="top_con">
					<span>肌肉量 (kg)</span>
					<el-input @input="valueInput('Musl')" @blur="valueChange()" v-model="form.Musl" :disabled="edit"></el-input>
				</div>
				<div class="top_con">
					<span>脂肪量 (kg)</span>
					<el-input @input="valueInput('ph_fat_qua')" @blur="valueChange()" v-model="form.ph_fat_qua" :disabled="edit"></el-input>
				</div>
				<div class="top_con">
					<span>身体水分 (kg)</span>
					<el-input @input="valueInput('ph_wat_qua')" @blur="valueChange()" v-model="form.ph_wat_qua" :disabled="edit"></el-input>
				</div>
				<div class="top_con" v-if="!moreType">
					<span>蛋白质 (kg)</span>
					<el-input @input="valueInput('protein')" @blur="valueChange()" v-model="form.protein" :disabled="edit"></el-input>
				</div>
				<div class="top_con" v-if="!moreType">
					<span>左上肢肌肉量 (kg)</span>
					<el-input @input="valueInput('LarmMuscle')" @blur="valueChange()" v-model="form.LarmMuscle" :disabled="edit"></el-input>
				</div>
				<div class="top_con" v-if="!moreType">
					<span>右上肢肌肉量 (kg)</span>
					<el-input @input="valueInput('RarmMuscle')" @blur="valueChange()" v-model="form.RarmMuscle" :disabled="edit"></el-input>
				</div>
				<div class="top_con" v-if="!moreType">
					<span>躯干肌肉量 (kg)</span>
					<el-input @input="valueInput('BtrunkMuscle')" @blur="valueChange()" v-model="form.BtrunkMuscle" :disabled="edit"></el-input>
				</div>
				<div class="top_con" v-if="!moreType">
					<span>左下肢肌肉量 (kg)</span>
					<el-input @input="valueInput('LlagMuscle')" @blur="valueChange()" v-model="form.LlagMuscle" :disabled="edit"></el-input>
				</div>
				<div class="top_con" v-if="!moreType">
					<span>右下肢肌肉量 (kg)</span>
					<el-input @input="valueInput('RlagMuscle')" @blur="valueChange()" v-model="form.RlagMuscle" :disabled="edit"></el-input>
				</div>
				<div class="top_con" v-if="!moreType">
					<span>基础代谢量 (kcal)</span>
					<el-input @input="valueInput('bmr')" @blur="valueChange()" v-model="form.bmr" :disabled="edit"></el-input>
				</div>
				<div class="top_con" v-if="!moreType">
					<span>身体年龄 (岁)</span>
					<el-input @input="valueInput('BodyAge')" @blur="valueChange()" v-model="form.BodyAge" :disabled="edit"></el-input>
				</div>
				<div class="top_con" v-if="!moreType">
					<span>总能量消耗 (kcal)</span>
					<el-input @input="valueInput('ENELGDay')" @blur="valueChange()" v-model="form.ENELGDay" :disabled="edit"></el-input>
				</div>
				<div class="top_con" v-if="!moreType">
					<span>内脏脂肪面积 (m2)</span>
					<el-input @input="valueInput('Inprotein')" @blur="valueChange()" v-model="form.Inprotein" :disabled="edit"></el-input>
				</div>
				<div class="top_con" v-if="!moreType">
					<span>腰臀比 (%)</span>
					<el-input @input="valueInput('WHR')" @blur="valueChange()" v-model="form.WHR" :disabled="edit"></el-input>
				</div>
			</div>
			<div class="sub_content_bot">
				<p class="bot_title">基础分析</p>
				<ul>
					<li v-for="(item, index) in imgData" :key="index">
						<img :src="item.img" alt="" />
						<p>{{ item.type ? item.title : '' }}</p>
						<div class="li_title" :style="{ backgroundImage: `url('${item.cimg}')`, color: item.type ? '#fff' : '#666' }">
							{{ item.content }}
						</div>
					</li>
					<!-- <li>
            <img :src="manImg" alt="">
            <p>低体重，建议速速增肌，保证力量</p>
            <div class="li_title">
              低体重
            </div>
          </li>
          <li>
            <img :src="manImg" alt="">
            <p>标准体型，收获良好体态，加油保持哦</p>
            <div class="li_title">
              标准
            </div>
          </li>
          <li>
            <img :src="manImg" alt="">
            <p>建议减减体脂，挥去负担</p>
            <div class="li_title">
              高体脂
            </div>
          </li>
          <li>
            <img :src="manImg" alt="">
            <p>肌肉充足，运动健将继续保持哦</p>
            <div class="li_title">
              肌肉充足
            </div>
          </li> -->
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import manImg from './../../../static/man.png'
import manCImg from './../../../static/man_color.png'
import womanImg from './../../../static/woman.png'
import womanCImg from './../../../static/woman_color.png'
import contentImg from './../../../static/content.png'
import contentCImg from './../../../static/content_color.png'
export default {
	data() {
		return {
			edit: this.$route.query.edit == '1' ? true : false,
			circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
			infoData: {},
			value1: new Date(),
			customDateArr: [],
			from: {},
			value: [],
			echartsData: {},
			form: {},
			moreType: true,
			manImg: null,
			manCImg: null,
			contentImg: contentImg,
			contentCImg: contentCImg,
			imgData: [
				{
					title: '',
					content: '低体重',
					img: '',
					cimg: '',
					type: false,
				},
				{
					title: 'x',
					content: '标准',
					img: '',
					cimg: '',
					type: false,
				},
				{
					title: 'x',
					content: '高体脂',
					img: '',
					cimg: '',
					type: false,
				},
				{
					title: '',
					content: '肌肉充足',
					img: '',
					cimg: '',
					type: false,
				},
			],
		}
	},
	async mounted() {
		await this.serveInfo().then(res => {
			this.create()
			this.getDate()
		})
		// setTimeout(() => {
		// 	this.create()
		// 	this.getDate()
		// }, 0)
	},
	computed: {
		customPickerOptions() {
			let that = this
			that.type = true
			return {
				cellClassName(date) {
					if (that.customDateArr.includes(that.getFullDate(date))) {
						return 'custom_date_class'
					}
				},
			}
		},
	},
	methods: {
		dateChange(date) {
			this.create()
			this.getDate()
		},
		getDate(startDate, endDate) {
			this.$axios
				.post(
					'/p/bodytest/getDate',
					this.$qs({
						staff_uuid: this.$route.params.uuid,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.customDateArr = res.data.data
					}
				})
		},
		// 格式化日期格式为 xxxx-xx-xx
		getFullDate(targetDate) {
			var D, y, m, d
			if (targetDate) {
				D = new Date(targetDate)
				y = D.getFullYear()
				m = D.getMonth() + 1
				d = D.getDate()
			} else {
				y = fullYear
				m = month
				d = date
			}
			m = m > 9 ? m : '0' + m
			d = d > 9 ? d : '0' + d
			return y + '-' + m + '-' + d
		},
		serveInfo() {
			return new Promise((resolve, reject) => {
				return this.$axios
					.post(
						'/p/injury/staffInfo',
						this.$qs({
							uuid: this.$route.params.uuid,
						})
					)
					.then(res => {
						if (res.data.code == 0) {
							this.infoData = res.data.data[0]
							this.manImg = this.infoData.sex == 1 ? manImg : womanImg
							this.manCImg = this.infoData.sex == 1 ? manCImg : womanCImg
							this.imgData.forEach(item => {
								item.img = this.manImg
								item.cimg = this.contentImg
							})
							resolve()
						}
					})
			})
		},
		reBack() {
			this.$router.push({
				path: '/conditionModule/weightManagement',
				query: {
					type: 3,
				},
			})
		},
		create() {
			var that = this
			let date = this.getFullDate(this.value1)
			this.$axios
				.post(
					'/p/bodytest/read',
					this.$qs({
						staff_uuid: that.$route.params.uuid,
						date: date,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						if (!res.data.data || res.data.data.rentitu) {
							that.imgData.forEach(item => {
								item.img = that.manImg
								item.cimg = that.contentImg
								item.title = ''
								item.type = false
							})
						}
						that.form = {
							weight: res.data.data ? res.data.data.weight : '',
							PhFatRate: res.data.data ? res.data.data.PhFatRate : '',
							excp_fat_qua: res.data.data ? res.data.data.excp_fat_qua : '',
							Musl: res.data.data ? res.data.data.Musl : '',
							ph_fat_qua: res.data.data ? res.data.data.ph_fat_qua : '',
							ph_wat_qua: res.data.data ? res.data.data.ph_wat_qua : '',
							protein: res.data.data ? res.data.data.protein : '',
							LarmMuscle: res.data.data ? res.data.data.LarmMuscle : '',
							RarmMuscle: res.data.data ? res.data.data.RarmMuscle : '',
							BtrunkMuscle: res.data.data ? res.data.data.BtrunkMuscle : '',
							LlagMuscle: res.data.data ? res.data.data.LlagMuscle : '',
							RlagMuscle: res.data.data ? res.data.data.RlagMuscle : '',
							bmr: res.data.data ? res.data.data.bmr : '',
							BodyAge: res.data.data ? res.data.data.BodyAge : '',
							ENELGDay: res.data.data ? res.data.data.ENELGDay : '',
							Inprotein: res.data.data ? res.data.data.Inprotein : '',
							WHR: res.data.data ? res.data.data.WHR : '',
						}
						if (res.data.data && res.data.data.rentitu) {
							setTimeout(() => {
								that.$nextTick(() => {
									that.$set(that.imgData[res.data.data.rentitu - 1], 'type', true)
									that.$set(that.imgData[res.data.data.rentitu - 1], 'img', that.manCImg)
									that.$set(that.imgData[res.data.data.rentitu - 1], 'cimg', that.contentCImg)
									that.$set(that.imgData[res.data.data.rentitu - 1], 'title', res.data.data.desc)
									// this.imgData[res.data.data.rentitu - 1].type = true
									// this.imgData[res.data.data.rentitu - 1].img = this.manCImg
									// this.imgData[res.data.data.rentitu - 1].cimg = this.contentCImg
									// this.imgData[res.data.data.rentitu - 1].title = res.data.data.desc
								})
							}, 100)
						}
					}
				})
		},
		change(index, title) {
			this.imgData.forEach(item => {
				item.type = false
				item.img = this.manImg
				item.cimg = this.contentImg
			})
			if (index != 0) {
				this.imgData[index - 1].type = true
				this.imgData[index - 1].title = title
				this.imgData[index - 1].img = this.manCImg
				this.imgData[index - 1].cimg = this.contentCImg
			}
		},
		statusChange(value, index) {
			let date = this.getFullDate(this.value1)
			this.$axios
				.post(
					'/p/statusrecord/add',
					this.$qs({
						staff_uuid: this.$route.params.uuid,
						date: date,
						type: index,
						value: value,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.$message({
							type: 'success',
							message: res.data.message,
						})
						this.create()
					}
				})
		},
		moreClick(type) {
			this.moreType = !this.moreType
		},
		// 输入数值
		valueChange() {
			let date = this.getFullDate(this.value1)
			this.$axios
				.post(
					'/p/bodytest/add',
					this.$qs({
						staff_uuid: this.$route.params.uuid,
						ExamTime: date,
						weight: this.form.weight,
						PhFatRate: this.form.PhFatRate,
						excp_fat_qua: this.form.excp_fat_qua,
						Musl: this.form.Musl,
						ph_fat_qua: this.form.ph_fat_qua,
						ph_wat_qua: this.form.ph_wat_qua,
						protein: this.form.protein,
						LarmMuscle: this.form.LarmMuscle,
						RarmMuscle: this.form.RarmMuscle,
						BtrunkMuscle: this.form.BtrunkMuscle,
						LlagMuscle: this.form.LlagMuscle,
						RlagMuscle: this.form.RlagMuscle,
						bmr: this.form.bmr,
						BodyAge: this.form.BodyAge,
						ENELGDay: this.form.ENELGDay,
						Inprotein: this.form.Inprotein,
						WHR: this.form.WHR,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.change(res.data.data.rentitu, res.data.data.desc)
					}
				})
		},
		valueInput(name) {
			let value = this.form[name]
			var regExp = /^[1-9][0-9]*[.]?([0-9]+)?$/
			if (!regExp.test(value)) {
				this.form[name] = ''
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.subjective_view {
	.interMant_top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 20px;
		background: #fff;
		margin-bottom: 10px;
		box-sizing: border-box;
		border-radius: 5px;
		.interTop_left {
			width: 70%;
			display: flex;
			align-items: center;
			font-size: 16px;
			color: #333;
			.el-avatar {
				width: 60px;
				height: 60px;
				img {
					width: 100%;
				}
			}
			span {
				margin-left: 5px;
				margin-right: 20px;
				font-size: 16px;
			}
		}
		.interTop_right {
			> .el-date-editor {
				margin-right: 20px;
				border-radius: 0;
				border-right-width: 0;
				border: none;
				margin-right: 60px;
				/deep/ .el-input__inner {
					border: none;
					border-radius: 0;
					border-bottom: 2px solid #ccc;
					&:focus {
						border-color: #0055e9;
					}
				}
				&:focus {
					border-color: #0055e9;
				}
			}
			> span {
				font-size: 16px;
				margin-right: 20px;
				&:hover {
					cursor: pointer;
				}
			}
		}
	}
}
.subjective_content {
	background: #fff;
	margin-top: 15px;
	border-radius: 5px;
	padding: 20px 40px 0 40px;
	display: flex;
	box-sizing: border-box;
	justify-content: space-between;
	flex-wrap: wrap;
	.sub_content_top {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		padding-top: 20px;
		position: relative;
		// height: auto;
		overflow: hidden;
		.more {
			position: absolute;
			bottom: 40px;
			right: 5px;
			font-size: 16px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #0055e9;
			i {
				font-size: 20px;
			}
			&:hover {
				cursor: pointer;
			}
		}
		// .iconBottomMove{
		// }
		// .iconTopMove{
		//   animation: iconTopMove 1s;
		// }
		.pack {
			position: absolute;
			bottom: 40px;
			right: 5px;
			font-size: 16px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #0055e9;
			i {
				font-size: 20px;
			}
			&:hover {
				cursor: pointer;
			}
		}
		.top_con {
			width: 30%;
			margin-right: 2%;
			display: flex;
			align-items: center;
			margin-bottom: 30px;
			border: 1px solid #dbdbdb;
			border-radius: 5px;
			/deep/ .el-input {
				width: 80%;
				.el-input__inner {
					border: none;
				}
			}
			span {
				display: inline-block;
				width: 160px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				background: #efefef;
				border-top-left-radius: 5px;
				border-bottom-left-radius: 5px;
				border-right: 1px solid #dbdbdb;
			}
		}
	}
	.sub_content_bot {
		width: 100%;
		border: 1px solid #ccc;
		border-radius: 5px;
		margin-bottom: 50px;
		.bot_title {
			text-align: center;
			margin-top: 30px;
			font-size: 20px;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: #1f2633;
			line-height: 28px;
		}
		ul {
			width: 100%;
			display: flex;
			margin: 40px 0;
			li {
				flex: 1;
				text-align: center;
				position: relative;
				.li_title {
					width: 45px;
					min-height: 60px;
					padding: 15px 0;
					// background: url('./../../../static/content.png');
					background-repeat: no-repeat;
					background-size: 100% 100%;
					position: absolute;
					bottom: 70px;
					right: 80px;
					writing-mode: tb-rl;
					line-height: 45px;
					letter-spacing: 5px;
					font-size: 17px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #666666;
				}
				p {
					font-size: 18px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #666666;
					text-align: center;
					margin-top: 10px;
					height: 20px;
					display: block;
				}
				img {
					margin: 0 auto;
					width: 119px;
				}
			}
		}
	}
}
.otim {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	word-break: break-all;
}
</style>

<style lang="scss">
.custom_date_class {
	span {
		background: #ea6151;
		color: red;
		border-radius: 50%;
		color: #fff !important;
		&:hover {
			background-color: #409eff;
		}
	}
	&::after {
		content: '';
		display: inline-block;
		position: absolute;
		width: 100%;
		font-size: 12px;
		color: red;
		bottom: -15px;
		left: 0;
		text-align: center;
		white-space: nowrap;
	}
}
</style>
